import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Typography } from '@mui/material';
import { SYMBOL } from '../../config';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const WithdrawalViewModal = ({ handleClose, open, viewData, userData, earning }) => {
  console.log(viewData);
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiPaper-root': {
            width: '800px !important',
            maxWidth: '100% !important',
            background: '#1d1e1f',
            color: '#fff',
            border: ' 1px solid #ffffff59',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #ffffff59',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div style={{ color: '#fff9e6' }}>View</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            container
            spacing={2}
            sx={{
              '& p': {
                border: '0.5px dashed #ffffff59',
                borderRadius: '5px',
                p: '5px',
                mb: '8px',
                color: 'rgb(255 255 255 / 74%)',
              },
            }}
          >
            <Grid item md={6} sm={12} xs={12}>
              <>
                <Typography
                  component={'div'}
                  sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    pb: '0.1rem',
                    mb: '0.5rem',
                    borderBottom: '1px solid #ffffff59',
                  }}
                >
                  Basic Information:-
                </Typography>
                <>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>User ID: {viewData?.attributes?.userid}</Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    First Name: <span>{userData?.firstName || 'NA'}</span>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Last Name: <span>{userData?.lastName || 'NA'}</span>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>Email: {userData?.email}</Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Available Balance: $ {Number(earning) ? earning : 0}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Withdrawal Amount: $ {viewData?.attributes?.amount}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Withdrawal Method:{' '}
                    {viewData?.attributes?.method === 'Crypto'
                      ? 'ERC 20 Crypto Wallet Address'
                      : viewData?.attributes?.method}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>Status: {viewData?.attributes?.status}</Typography>{' '}
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Transaction ID: {viewData?.attributes?.txn_id === null ? 'NA' : viewData?.attributes?.txn_id}
                  </Typography>{' '}
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Transaction Date: {viewData?.attributes?.txn_date || 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    RequestedAt: {new Date(viewData?.attributes?.createdAt).toDateString()}
                  </Typography>
                </>
              </>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <>
                <Typography
                  component={'div'}
                  sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    pb: '0.1rem',
                    mb: '0.5rem',
                    borderBottom: '1px solid #ffffff59',
                  }}
                >
                  Payment Information:-
                </Typography>
                <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                  Account Name: {userData?.accountName ? userData?.accountName : 'NA'}
                </Typography>
                <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                  Account Number: {userData?.accountNumber ? userData?.accountNumber : 'NA'}
                </Typography>
                <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                  Bank Address: {userData?.bankAddress ? userData?.bankAddress : 'NA'}
                </Typography>
                <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                  Bank Name: {userData?.bankName ? userData?.bankName : 'NA'}
                </Typography>
                <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                  Branch Number: {userData?.branchNumber ? userData?.branchNumber : 'NA'}
                </Typography>
                <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                  ERC 20 Crypto Wallet Address: {userData?.cryptoWallet ? userData?.cryptoWallet : 'NA'}
                </Typography>
                <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                  Paypal Email: {userData?.paypalEmail ? userData?.paypalEmail : 'NA'}
                </Typography>
                <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                  eTransfer Email: {userData?.ePaymentEmail ? userData?.ePaymentEmail : 'NA'}
                </Typography>
              </>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default WithdrawalViewModal;
