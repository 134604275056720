import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CollectionsIcon from '@mui/icons-material/Collections';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import ImageGallery from 'react-image-gallery';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Gallery } from 'react-grid-gallery';
import axios from 'axios';
import { API_URL } from '../../config';
import ViewImage from './ViewImage';
import Iconify from '../../components/iconify';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const useStyles = makeStyles({
  tab_box1: {
    position: 'relative',
    '@media (max-width:991px)': {
      padding: '20px',
    },
    '@media (max-width:600px)': {
      padding: '10px',
      // "& .MuiTabs-scroller":{
      //   overflowX:"scroll !important"
      // }
    },
    '& .MuiButtonBase-root.Mui-selected': {
      color: '#c8c862 !important',
      textShadow: 'inherit !important',
      fontSize: '18px !important',
      padding: '10px !important',
    },
    '& .MuiButtonBase-root': {
      color: '#fff',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#c8c862 !important',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  btns: {
    color: '#fff',
    lineHeight: '19.92px !important',
    fontSize: '14px !important',
    height: '45px',
    width: '200px',
    fontWeight: '400 !important',
    fontFamily: 'Exo 2 !important',
    transition: '0.1s all !important',
    borderRadius: '8px !important',
    background: '#000 !important',
    border: 'none',
    '&:hover': {
      background: '#000 !important',
      border: 'none',
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed !important',
      pointerEvents: 'auto !important',
      color: 'rgb(255 255 255 / 38%) !important',
      '&:hover': {
        opacity: '1',
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const ViewModal = ({
  handleClose,
  open,
  viewData,
  images,
  status,
  createCollection,
  collectionData,
  getGirlCollectionData,
  approvedImages,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(
    0
    //   () => {
    //   const tabValue = parseInt(localStorage.getItem("selectedTab"));
    //   return isNaN(tabValue) ? 0 : tabValue;
    // }
  );
  const [girlSingleList, setGirlSingleList] = useState([]);
  const getGirlImages = async () => {
    await axios({
      method: 'GET',
      url: `${API_URL}/api/users?filters[id][$eq]=${viewData?.id}&populate=*`,
      headers: {
        'Accept-Encoding': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        setGirlSingleList(_data.data);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  useEffect(() => {
    getGirlImages();
  }, []);
  const [files, setFiles] = useState(null);
  // const handleFileChange = async (e) => {
  //   const selectedFile = e.target.files[0];
  //   setFiles(selectedFile);
  // };
  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    // Check if file is uploaded
    if (!selectedFile) {
      // Show error message for no file selected
      console.error('No file selected.');
      return;
    }

    // Create a new FileReader
    const reader = new FileReader();

    // Read the file as an image
    reader.readAsDataURL(selectedFile);

    // Define a callback function for when the file is loaded
    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      // Define a function to check the image dimensions
      img.onload = function () {
        const width = img.width;
        const height = img.height;

        // Check if the image dimensions match the required dimensions
        if (width === 780 && height === 1140) {
          // If dimensions match, set the file in the state
          setFiles(selectedFile);
        } else {
          // If dimensions don't match, show error message
          alert('Image dimensions must be 780px x 1140px.');
        }
      };
    };
  };

  useEffect(() => {
    if (files != null) {
      upload();
    }
  }, [files]);
  const upload = async () => {
    setUploadLoader(true);
    try {
      const imagesData = new FormData();
      imagesData.append('files', files);
      imagesData.append('ref', 'plugin::users-permissions.user');
      imagesData.append('field', 'images');
      imagesData.append('refId', viewData?.id);
      const jwt = localStorage.getItem('token');

      const response = await axios.post(`${API_URL}/api/upload`, imagesData, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setUploadLoader(false);
        getGirlImages();
      } else {
        setUploadLoader(false);
        console.error('Failed to upload');
      }
    } catch (error) {
      setUploadLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    setValue(createCollection);
  }, [createCollection]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // navigate(`/profile/${tabNames[newValue]}`);
  };
  const [image, setImage] = useState([]);

  const [collectionImages, setCollectionImages] = useState([]);
  const [approvedCollectionImages, setApprovedCollectionImages] = useState([]);
  // console.log(approvedImages, approvedCollectionImages, collectionImages);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesFile, setSelectedImagesFile] = useState([]);
  const [cropCheckbox, setCropCheckbox] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const createCollcetion = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.post(
        `${API_URL}/api/create/collection/${viewData?.id}`,
        {
          collections: selectedImages,
          croppingRequired: cropCheckbox,
        },
        config
      );
      if (data.data.status === 200) {
        console.log(data.data);
        getGirlCollectionData();
        // handleClose()
      }
    } catch (error) {
      console.log('catch', error);
    }
  };
  useEffect(() => {
    if (girlSingleList?.[0]?.images) {
      const _image = girlSingleList?.[0]?.images?.map((e, i) => {
        return {
          original: `${e.url}`,
          thumbnail: `${e.url}`,
          originalClass: 'custom-image',
          thumbnailClass: 'custom-thumbnail',
        };
      });
      setImage(_image);
    }
    // eslint-disable-next-line
  }, [girlSingleList?.[0]?.images]);
  useEffect(() => {
    if (girlSingleList?.[0]?.images) {
      const _collectionImages = girlSingleList?.[0]?.images?.map((e, i) => {
        return {
          src: `${e.url}`, // console.log(approvedImages, approvedCollectionImages, collectionImages);
          // width: 200,
          // height: 200,
          id: e.id,
          // caption: "After Rain (Jeshu John - designerspics.com)",
        };
      });
      setCollectionImages(_collectionImages);
    }
    // eslint-disable-next-line
  }, [girlSingleList?.[0]?.images]);
  useEffect(() => {
    if (approvedImages) {
      const _collectionImages = approvedImages?.map((e, i) => {
        return {
          src: `${e?.image?.url}`,
          // width: 200,
          // height: 200,
          id: e.id,
          // caption: "After Rain (Jeshu John - designerspics.com)",
        };
      });
      setApprovedCollectionImages(_collectionImages);
    }
    // eslint-disable-next-line
  }, [approvedImages]);

  const handleSelect = (index) => {
    const nextImages = collectionImages.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    const selectedImages = [];
    const selectedImagesFile = [];
    // console.log(selectedImages);

    nextImages.map((image, i) => {
      if (image.isSelected) {
        selectedImages.push(image.id);
        selectedImagesFile.push(image.src);
      }

      return false;
    });
    setSelectedImagesFile(selectedImagesFile);
    setSelectedImages(selectedImages);

    setCollectionImages(nextImages);
  };

  const [index, setIndex] = useState(-1);
  const [prevIndex, setPrevIndex] = useState(null);
  const [nextIndex, setNextIndex] = useState(null);
  const [nextImage, setNextImage] = useState(null);
  const [prevImage, setPrevImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);

  const handleClick = (_index, item) => {
    const currentImage = collectionImages?.[_index];
    setCurrentImage(currentImage);
    const nextIndex = (_index + 1) % collectionImages?.length;
    const nextImage = collectionImages?.[nextIndex] || currentImage;
    const prevIndex = (_index + collectionImages?.length - 1) % collectionImages?.length;
    setPrevIndex(prevIndex);
    setNextIndex(nextIndex);
    setNextImage(nextImage);

    const prevImage = collectionImages?.[prevIndex] || currentImage;
    setPrevImage(prevImage);
    setIndex(_index);
  };
  const handleDelete = async () => {
    setDeleteLoader(true);
    try {
      // Use Promise.all to parallelize delete requests
      await Promise.all(
        selectedImages.map(async (imageId) => {
          await axios.delete(`${API_URL}/api/upload/files/${imageId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
        })
      );
      setDeleteLoader(false);
      getGirlImages();
    } catch (error) {
      setDeleteLoader(false);
      console.log(error);
    }
  };
  const handleDownload = () => {
    selectedImagesFile.forEach(async (imageUrl, index) => {
      const imgName = `image_${index + 1}.jpg`;
      // const imageBlob = await fetch(imageUrl)
      //   .then((response) => response.arrayBuffer())
      //   .then((buffer) => new Blob([buffer], { type: "image/jpeg" }));

      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(imageBlob);
      // link.download = imgName;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = imageUrl;
      link.download = imgName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  const proxyServerUrl = 'http://localhost:3001';
  const downloadImg = async (imageSrc, imgName) => {
    const imageBlob = await fetch(imageSrc)
      .then((response) => response.arrayBuffer())
      .then((buffer) => new Blob([buffer], { type: 'image/jpeg' }));
    const link = document.createElement('a');
    link.href = URL.createObjectURL(imageBlob);
    link.download = imgName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiPaper-root': {
            width: '800px !important',
            maxWidth: '100% !important',
            background: '#1d1e1f',
            color: '#fff',
            border: ' 1px solid #ffffff59',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #ffffff59',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiTabs-flexContainer': {
            overflowX: 'auto !important',
            '&::-webkit-scrollbar': {
              height: 4,
            },
            // scrollbarWidth: "none",
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div style={{ color: '#fff9e6' }}>View</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box className={classes.tab_box1}>
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab
                    sx={{
                      fontSize: '18px !important',
                      padding: '10px !important',
                    }}
                    label="Basic Info"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{
                      fontSize: '18px !important',
                      padding: '10px !important',
                    }}
                    label="Payment Info"
                    {...a11yProps(1)}
                  />
               {viewData?.user_type === "Girl" &&    <Tab
                    sx={{
                      fontSize: '18px !important',
                      padding: '10px !important',
                    }}
                    label="Look Info"
                    {...a11yProps(2)}
                  />}
             {viewData?.user_type === "Girl" &&      <Tab
                    sx={{
                      fontSize: '18px !important',
                      padding: '10px !important',
                    }}
                    label="Collections"
                    {...a11yProps(3)}
                  />}
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <Box
                  sx={{
                    '& p': {
                      border: '0.5px dashed #ffffff59',
                      borderRadius: '5px',
                      p: '8px',
                      fontSize: '16px',
                      mb: '8px',
                      color: 'rgb(255 255 255 / 94%)',
                    },
                  }}
                >
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    First Name: <span>{viewData?.firstName || 'NA'}</span>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Last Name: <span>{viewData?.lastName || 'NA'}</span>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>Username: {viewData?.username}</Typography>
                  {/* <Typography sx={{ fontSize: '1rem', py: '5px' }}>Wallet: {viewData?.wallet}</Typography> */}
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>Email: {viewData?.email}</Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>Status: {viewData?.status}</Typography>
                  {viewData?.commission !==null && (
                    <Typography sx={{ fontSize: '1rem', py: '5px' }}>Commission: {viewData?.commission}%</Typography>
                  )}
                  {viewData?.referredBy && (
                    <Typography sx={{ fontSize: '1rem', py: '5px' }}>ReferredBy: {viewData?.referredBy}</Typography>
                  )}
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Account Created: {new Date(viewData?.createdAt).toDateString()}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Last Updated: {new Date(viewData?.updatedAt).toDateString()}
                  </Typography>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box
                  sx={{
                    '& p': {
                      border: '0.5px dashed #ffffff59',
                      borderRadius: '5px',
                      p: '8px',
                      mb: '8px',
                      fontSize: '16px',
                      color: 'rgb(255 255 255 / 94%)',
                    },
                  }}
                >
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Account Name: {viewData?.accountName ? viewData?.accountName : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Account Number: {viewData?.accountNumber ? viewData?.accountNumber : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Bank Address: {viewData?.bankAddress ? viewData?.bankAddress : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Bank Name: {viewData?.bankName ? viewData?.bankName : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Branch Number: {viewData?.branchNumber ? viewData?.branchNumber : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    ERC 20 Crypto Wallet Address: {viewData?.cryptoWallet ? viewData?.cryptoWallet : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Paypal Email: {viewData?.paypalEmail ? viewData?.paypalEmail : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    eTransfer Email: {viewData?.ePaymentEmail ? viewData?.ePaymentEmail : 'NA'}
                  </Typography>
                </Box>
              </TabPanel>
              {viewData?.user_type === "Girl" && <TabPanel value={value} index={2}>
                <Box
                  sx={{
                    '& p': {
                      border: '0.5px dashed #ffffff59',
                      borderRadius: '5px',
                      p: '8px',
                      fontSize: '16px',
                      mb: '8px',
                      color: 'rgb(255 255 255 / 94%)',
                    },
                  }}
                >
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Ethnicity:{' '}
                    {viewData?.ethnicity?.split(',')?.map((e, i, arr) => (
                      <>
                        <span>{e}</span>
                        {i !== arr.length - 1 && ', '}
                      </>
                    ))}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Body Type:{' '}
                    {viewData?.bodyType?.split(',')?.map((e, i, arr) => (
                      <>
                        <span>{e}</span>
                        {i !== arr.length - 1 && ', '}
                      </>
                    ))}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Hair Color: <span>{viewData?.hairColor || 'NA'}</span>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Eye Color: <span>{viewData?.eyeColor || 'NA'}</span>
                  </Typography>
                </Box>
              </TabPanel>}
             {viewData?.user_type === "Girl" &&  <TabPanel value={value} index={3}>
                <Box>
                  {
                    image ?
                     (
                    status === 'Approved' ? (
                      <ImageGallery
                        items={image}
                        // showNav={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        disableSwipe
                        disableThumbnailSwipe
                        slideDuration={0}
                      />
                    ) : (
                      <>
                        <Gallery
                          enableImageSelection={!approvedCollectionImages.length}
                          images={approvedCollectionImages.length ? approvedCollectionImages : collectionImages}
                          onClick={handleClick}
                          onSelect={handleSelect}
                        />
                        <Box
                          sx={{
                            mt: '1rem',
                            textAlign: 'center',
                            p: '1rem',
                          }}
                        >
                          {selectedImages?.length === 0 && !collectionData?.created && (
                            <Typography color="#fff" pb={'0.5rem'} fontSize={'1rem'}>
                              Please Select image before creating collections.
                            </Typography>
                          )}
                          {!collectionData?.created && (
                            <>
                              <Typography sx={{ fontWeight: '600', marginBottom: '0.3rem' }}>
                                (Recommended Image Upload Size: 780px X 1140px)
                              </Typography>
                              <Box pb={'1rem'}>
                                <input
                                  type="checkbox"
                                  // style={{ cursor: 'pointer' }}
                                  value={cropCheckbox}
                                  checked={cropCheckbox}
                                  disabled
                                  onChange={() => setCropCheckbox(!cropCheckbox)}
                                />{' '}
                                <span>Remove background (API Required)</span>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  gap: '0.5rem',
                                  flexDirection: { sm: 'row', xs: 'column' },
                                  flexWrap: 'wrap',
                                }}
                              >
                                <Button
                                  className={classes.btns}
                                  disabled={selectedImages?.length === 0}
                                  onClick={createCollcetion}
                                  startIcon={<CollectionsIcon />}
                                >
                                  Create Collection
                                </Button>

                                <Button
                                  component="label"
                                  variant="outlined"
                                  className={classes.btns}
                                  startIcon={!uploadLoader && <CloudUploadIcon />}
                                >
                                  {uploadLoader ? (
                                    <CircularProgress
                                      sx={{
                                        width: '18px !important',
                                        height: '18px !important',
                                        color: '#e4dbe7',
                                      }}
                                    />
                                  ) : (
                                    'Upload'
                                  )}
                                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleFileChange} />
                                </Button>
                                <Button
                                  component="label"
                                  variant="outlined"
                                  className={classes.btns}
                                  onClick={handleDelete}
                                  disabled={selectedImages?.length === 0}
                                  startIcon={!deleteLoader && <DeleteIcon />}
                                >
                                  {deleteLoader ? (
                                    <CircularProgress
                                      sx={{
                                        width: '18px !important',
                                        height: '18px !important',
                                        color: '#e4dbe7',
                                      }}
                                    />
                                  ) : (
                                    'Delete'
                                  )}
                                </Button>
                                <Button
                                  component="label"
                                  variant="outlined"
                                  className={classes.btns}
                                  startIcon={<CloudDownloadIcon />}
                                  disabled={selectedImages?.length === 0}
                                  onClick={handleDownload}
                                >
                                  Download
                                </Button>
                                {/* <img src='https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_640.jpg' alt='random'/>
                                <Button onClick={()=>downloadImg("https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_640.jpg","logo")}> Download Image</Button>
                                <img src='https://dreamgirls-strapi.s3.us-west-2.amazonaws.com/311694521669013bannerimg5_5dc0c9acd8.jpg' alt='random'/>
                                <Button onClick={()=>downloadImg("https://dreamgirls-strapi.s3.us-west-2.amazonaws.com/311694521669013bannerimg5_5dc0c9acd8.jpg","logo")}> Download Image</Button> */}
                              </Box>
                            </>
                          )}
                          {collectionData?.created && !collectionData?.published && (
                            <Typography color="yellow" fontSize={'1.2rem'} pt={'0.5rem'}>
                              Creating Collections...
                            </Typography>
                          )}

                          {collectionData?.created && collectionData?.published && (
                            <Typography
                              color="green"
                              fontSize={'1.2rem'}
                              sx={{
                                mt: '0.5rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '0.5rem',
                              }}
                            >
                              <Iconify icon={'ant-design:check-circle-outlined'} /> Collections Created.
                            </Typography>
                          )}
                        </Box>
                      </>
                    )
                  ) : (
                    <Box
                      sx={{
                        fontSize: '16px',
                        fontWeight: '600',
                        textAlign: 'center',
                        py: '2rem',
                      }}
                    >
                      Image not found.
                    </Box>
                  )}
                  {currentImage && (
                    <ViewImage
                      handleClose={() => setCurrentImage(null)}
                      open={currentImage !== null}
                      image={currentImage.src}
                    />
                  )}
                </Box>
              </TabPanel>}
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ViewModal;
