import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Snackbar,
  Alert,
  Box,
} from '@mui/material';
import { useAccount } from 'wagmi';
import axios from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { API_URL } from '../config';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import GirlTableListRow from './GirlTableListRow';
import ViewModal from './ViewModal/ViewModal';
import { ConnectWalletBtn } from '../components/ConnectWallet';
import AddGirlModal from './AddGirlModal';

// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'username', label: 'Username', alignRight: false },
  { id: 'girl', label: 'Referred Girls', alignRight: false },
  // { id: 'ipfs', label: 'IPFS', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false, filter: true },
  { id: 'Account Status', label: 'Account Status', alignRight: false },
  { id: 'KYC Status', label: 'KYC Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function GirlPage() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [girlData, setGirlData] = useState([]);
  const [photographerData, setPhotographerData] = useState([]);
  const [affiliateData, setAffiliateData] = useState([]);
  const [girlList, setGirlList] = useState([]);

  const [orderBy, setOrderBy] = useState('name');
  const [searchParams] = useSearchParams();
  const referredBy = searchParams.get('referredBy');
  const [filterName, setFilterName] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [addGirlOpen, setAddGirlOpen] = useState(false);
  const [showSnackbarRej, setShowSnackbarRej] = useState(false);
  const [statusFilter, setStatusFilter] = useState('Pending');
  const navigate = useNavigate();
  const location = useLocation();

  const handleFilter = (e) => {
    navigate(`?referredBy=${e.target.value}`);
    setFilterValue(e.target.value);
  };
  useEffect(() => {
    getPhotographerData();
    getAffiliateData();
    getGirlListData()
  }, []);

  useEffect(() => {
    getGirlData();
    // if(filterValue===""){
    //   navigate(``)
    // }
  }, [filterValue, statusFilter]);
  useEffect(() => {
    if(referredBy){
      setFilterValue(referredBy)
      setStatusFilter("All")
    }else{
      setFilterValue("")
      setStatusFilter("Pending")
    }
  }, [referredBy]);

  const getAffiliateData = async () => {
    await axios({
      method: 'GET',
      url: `${API_URL}/api/users?filters[user_type][$eq]=Affiliate&populate=*`,
      headers: {
        'Accept-Encoding': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        // console.log(_data);
        setAffiliateData(_data.data);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  const getGirlListData = async () => {
    await axios({
      method: 'GET',
      url: `${API_URL}/api/users?filters[user_type][$eq]=Girl&populate=*`,
      headers: {
        'Accept-Encoding': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        // console.log(_data);
        setGirlList(_data.data);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  const getPhotographerData = async () => {
    await axios({
      method: 'GET',
      url: `${API_URL}/api/users?filters[user_type][$eq]=Photographer&populate=*`,
      headers: {
        'Accept-Encoding': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        // console.log(_data);
        setPhotographerData(_data.data);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  const getGirlData = async () => {
    await axios({
      method: 'GET',
      url: referredBy
        ? `${API_URL}/api/users?filters[user_type][$eq]=Girl${
            statusFilter !== 'All' ? `&filters[status][$eq]=${statusFilter}` : ''
          }${`&filters[referredBy][$eq]=${referredBy}`}&populate=*`
        : `${API_URL}/api/users?filters[user_type][$eq]=Girl${
            statusFilter !== 'All' ? `&filters[status][$eq]=${statusFilter}` : ''
          }${filterValue !== '' ? `&filters[referredBy][$eq]=${filterValue}` : ''}&populate=*`,
      headers: {
        'Accept-Encoding': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        // console.log(_data);
        setGirlData(_data.data.reverse());
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  console.log(filterValue);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = girlData?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setPage(0);
    setFilterName(searchQuery);
    if (!searchQuery) {
      getGirlData();
      return;
    }
    const filteredData = girlData.filter(
      (user) =>
        user?.firstName?.toLowerCase().includes(searchQuery) ||
        user?.email?.toLowerCase().includes(searchQuery) ||
        user?.wallet?.toLowerCase().includes(searchQuery) ||
        user?.status?.toLowerCase().includes(searchQuery)
    );

    setGirlData(filteredData);
  };

  // const handleFilterByName = async (event) => {
  //   const searchQuery = event.target.value.toLowerCase();
  //   setPage(0);
  //   setFilterName(searchQuery);

  //   try {
  //     const response = await axios({
  //       method: 'GET',
  //       url: `${API_URL}/api/users?filters[firstName][$eq]=${searchQuery}`,
  //       headers: {
  //         'Accept-Encoding': 'application/json',
  //         // 'Origin' : process.env.ORIGIN
  //       },
  //     });
  //     console.log(response);
  //     setGirlData(response.data);
  //   } catch (error) {
  //     console.error('Error fetching girl data:', error);
  //     // Handle the error appropriately
  //   }
  // };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - girlData?.length) : 0;

  const filteredUsers = applySortFilter(girlData, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Snackbar open={showSnackbar}>
        <Alert
          severity={'success'}
          style={{
            backgroundColor: 'green',
            color: '#fff',
          }}
        >
          Approved Successfully
        </Alert>
      </Snackbar>
      <Snackbar open={showSnackbarRej} sx={{
        "& svg":{
          color:"#fff"
        }
      }}>
        <Alert style={{ backgroundColor: 'red', color: '#fff' }} severity={'error'}>
          Rejected Successfully
        </Alert>
      </Snackbar>
      <Helmet>
        <title> Girls | Admin Panel </title>
      </Helmet>

      <Container
        sx={{
          '& select': {
            '&:focus-visible': {
              outline: '0',
            },
          },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{
          "& div":{
            width:{sm:"auto",xs:"100%"}
          },
          "& select":{
            width:{sm:"auto",xs:"100% !important"}
          },
        }} mb={5} flexWrap={"wrap"}>
          <Typography variant="h4" gutterBottom>
            Girls
          </Typography>

          <Box>
            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Filter By Photographer :-</Typography>
            <select
              value={filterValue}
              id="filter"
              name="filter"
              onChange={handleFilter}
              style={{
                padding: '12px 5px',
                width: '200px',
                background: '',
                fontSize: '16px',
                borderRadius: '5px',
                border: '1px solid #CCCCCC',
                color: '#000',
              }}
            >
              <option value={''}>--Filter By--</option>

              {photographerData?.map((e, i) => (
                <option value={e?.username} key={i}>
                  {e?.firstName} {e?.lastName}
                </option>
              ))}
            </select>
          </Box>
          <Box>
            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Filter By Affiliate :-</Typography>
            <select
              value={filterValue}
              id="filter"
              name="filter"
              onChange={handleFilter}
              style={{
                padding: '12px 5px',
                width: '200px',
                background: '',
                fontSize: '16px',
                borderRadius: '5px',
                border: '1px solid #CCCCCC',
                color: '#000',
              }}
            >
              <option value={''}>--Filter By--</option>

              {affiliateData?.map((e, i) => (
                <option value={e?.username} key={i}>
                  {e?.firstName} {e?.lastName}
                </option>
              ))}
            </select>
          </Box>
          <Box>
            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Filter By Girl :-</Typography>
            <select
              value={filterValue}
              id="filter"
              name="filter"
              onChange={handleFilter}
              style={{
                padding: '12px 5px',
                width: '200px',
                background: '',
                fontSize: '16px',
                borderRadius: '5px',
                border: '1px solid #CCCCCC',
                color: '#000',
              }}
            >
              <option value={''}>--Filter By--</option>

              {girlList?.map((e, i) => (
                <option value={e?.username} key={i}>
                  {e?.firstName} {e?.lastName}
                </option>
              ))}
            </select>
          </Box>
          <Button
                    sx={{
                      mt:"1.5rem",
                      border: '2px solid #6373811f',
                      padding: '12px 25px',
                      background: 'rgba(145, 158, 171, 0.16)',
                      cursor: 'pointer',
                      borderRadius: '5px',
                      lineHeight: 'normal',
                      fontWeight: '500',
                      color: '#060a15',
                      textTransform: 'capitalize',
                      letterSpacing: 'initial',
                    }}
                   onClick={()=>setAddGirlOpen(true)}
                    type="button"
                  >
                    Add Girl
                  </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  type={'Girl'}
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={girlData?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  onStatusFilterChange={setStatusFilter}
                  statusFilter={statusFilter}
                  setListData={setGirlData}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, email, status, wallet, avatarUrl, ipfs } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <GirlTableListRow
                        row={row}
                        selected={selected}
                        handleClick={handleClick}
                        setShowSnackbar={setShowSnackbar}
                        getGirlData={getGirlData}
                        setShowSnackbarRej={setShowSnackbarRej}
                        referredBy={referredBy}
                      />
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {girlData.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          {isNotFound && (
                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          )}
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={girlData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <AddGirlModal open={addGirlOpen} handleClose={()=>setAddGirlOpen(false)} getGirlData={getGirlData}/>
      </Container>
    </>
  );
}
