import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import 'react-image-gallery/styles/css/image-gallery.css';
import axios from 'axios';
import { API_URL } from '../../config';
import ConfirmationModal from '../ConfirmationModal';

const useStyles = makeStyles({
  tab_box1: {
    position: 'relative',
    '@media (max-width:991px)': {
      padding: '20px',
    },
    '@media (max-width:600px)': {
      padding: '10px',
      // "& .MuiTabs-scroller":{
      //   overflowX:"scroll !important"
      // }
    },
    '& .MuiButtonBase-root.Mui-selected': {
      color: '#c8c862 !important',
      textShadow: 'inherit !important',
      fontSize: '18px !important',
      padding: '10px !important',
    },
    '& .MuiButtonBase-root': {
      color: '#fff',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#c8c862 !important',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  btns: {
    color: '#fff',
    lineHeight: '19.92px !important',
    fontSize: '14px !important',
    height: '45px',
    width: '200px',
    fontWeight: '400 !important',
    fontFamily: 'Exo 2 !important',
    transition: '0.1s all !important',
    borderRadius: '8px !important',
    background: '#000 !important',
    border: 'none',
    '&:hover': {
      background: '#000 !important',
      border: 'none',
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed !important',
      pointerEvents: 'auto !important',
      color: 'rgb(255 255 255 / 38%) !important',
      '&:hover': {
        opacity: '1',
      },
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const ApproveAccountModal = ({ handleClose, open, viewData, getGirlData }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const [statusOpen, setStatusOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // navigate(`/profile/${tabNames[newValue]}`);
  };


  return (
    <>

    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiPaper-root': {
            width: '800px !important',
            maxWidth: '100% !important',
            background: '#1d1e1f',
            color: '#fff',
            border: ' 1px solid #ffffff59',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #ffffff59',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiTabs-flexContainer': {
            overflowX: 'auto !important',
            '&::-webkit-scrollbar': {
              height: 4,
            },
            // scrollbarWidth: "none",
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div style={{ color: '#fff9e6' }}>View</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box className={classes.tab_box1}>
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab
                    sx={{
                      fontSize: '18px !important',
                      padding: '10px !important',
                    }}
                    label="Basic Info"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{
                      fontSize: '18px !important',
                      padding: '10px !important',
                    }}
                    label="Payment Info"
                    {...a11yProps(1)}
                  />
                  {viewData?.user_type === 'Girl' && (
                    <Tab
                      sx={{
                        fontSize: '18px !important',
                        padding: '10px !important',
                      }}
                      label="Look Info"
                      {...a11yProps(2)}
                    />
                  )}
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <Box
                  sx={{
                    '& p': {
                      border: '0.5px dashed #ffffff59',
                      borderRadius: '5px',
                      p: '8px',
                      fontSize: '16px',
                      mb: '8px',
                      color: 'rgb(255 255 255 / 94%)',
                    },
                  }}
                >
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    First Name: <span>{viewData?.firstName || 'NA'}</span>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Last Name: <span>{viewData?.lastName || 'NA'}</span>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>Username: {viewData?.username}</Typography>
                  {/* <Typography sx={{ fontSize: '1rem', py: '5px' }}>Wallet: {viewData?.wallet}</Typography> */}
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>Email: {viewData?.email}</Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>Status: {viewData?.status}</Typography>
                  {viewData?.commission !== null && (
                    <Typography sx={{ fontSize: '1rem', py: '5px' }}>Commission: {viewData?.commission}%</Typography>
                  )}
                  {viewData?.referredBy && (
                    <Typography sx={{ fontSize: '1rem', py: '5px' }}>ReferredBy: {viewData?.referredBy}</Typography>
                  )}
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Account Created: {new Date(viewData?.createdAt).toDateString()}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                    Last Updated: {new Date(viewData?.updatedAt).toDateString()}
                  </Typography>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box
                  sx={{
                    '& p': {
                      border: '0.5px dashed #ffffff59',
                      borderRadius: '5px',
                      p: '8px',
                      mb: '8px',
                      fontSize: '16px',
                      color: 'rgb(255 255 255 / 94%)',
                    },
                  }}
                >
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Account Name: {viewData?.accountName ? viewData?.accountName : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Account Number: {viewData?.accountNumber ? viewData?.accountNumber : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Bank Address: {viewData?.bankAddress ? viewData?.bankAddress : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Bank Name: {viewData?.bankName ? viewData?.bankName : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Branch Number: {viewData?.branchNumber ? viewData?.branchNumber : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    ERC 20 Crypto Wallet Address: {viewData?.cryptoWallet ? viewData?.cryptoWallet : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    Paypal Email: {viewData?.paypalEmail ? viewData?.paypalEmail : 'NA'}
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', py: '5px', wordWrap: 'break-word' }}>
                    eTransfer Email: {viewData?.ePaymentEmail ? viewData?.ePaymentEmail : 'NA'}
                  </Typography>
                </Box>
              </TabPanel>
              {viewData?.user_type === 'Girl' && (
                <TabPanel value={value} index={2}>
                  <Box
                    sx={{
                      '& p': {
                        border: '0.5px dashed #ffffff59',
                        borderRadius: '5px',
                        p: '8px',
                        fontSize: '16px',
                        mb: '8px',
                        color: 'rgb(255 255 255 / 94%)',
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                      Ethnicity:{' '}
                      {viewData?.ethnicity?.split(',')?.map((e, i, arr) => (
                        <>
                          <span>{e}</span>
                          {i !== arr.length - 1 && ', '}
                        </>
                      ))}
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                      Body Type:{' '}
                      {viewData?.bodyType?.split(',')?.map((e, i, arr) => (
                        <>
                          <span>{e}</span>
                          {i !== arr.length - 1 && ', '}
                        </>
                      ))}
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                      Hair Color: <span>{viewData?.hairColor || 'NA'}</span>
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', py: '5px' }}>
                      Eye Color: <span>{viewData?.eyeColor || 'NA'}</span>
                    </Typography>
                  </Box>
                </TabPanel>
              )}
              <Box textAlign={'center'} mb={'1rem'}>
                <Button className={classes.btns} onClick={()=>setStatusOpen(true)}>
                  Approve Account
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
      
    </div>
    <ConfirmationModal statusOpen={statusOpen} setStatusOpen={setStatusOpen} viewData={viewData} getGirlData={getGirlData} handleCloseMenu={handleClose}/>
    </>
  );
};

export default ApproveAccountModal;
