import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { API_URL } from '../config/index';

const useStyles = makeStyles((theme) => ({
  bin1: {
    //   marginBottom: "0.5rem !important",
    width: '100%',
    border: '0.5px solid rgb(255 255 255 / 28%) !important',
    borderRadius: '8px ',
    background: '#1d1e1f !important',
    color: '#fff',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&:focus-Visible': {
      outline: 'none !important',
      border: 'none !important',
    },
    '&:hover': {
      border: 'none',
    },
    '& .MuiInputBase-root': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },

    '&:focus': {
      boxShadow: 'none !important',
      border: '0.5px solid #fff !important',
      borderRadius: '10px !important ',
    },
    '& input': {
      padding: '12px 15px',
      fontSize: '16px',
      borderRadius: '10px',
      fontWeight: '400',
      color: 'rgb(255 255 255 / 100%) !important',
      '&::placeholder': {
        color: 'rgb(255 255 255 / 70%) !important',
        fontSize: '15px',
        fontWeight: '400',
      },
    },
  },
  btn: {
    color: '#fff !important',
    lineHeight: '19.92px !important',
    fontSize: '14px !important',
    height: '45px',
    // width: '130px',
    padding: '10px 20px !important',
    fontWeight: '400 !important',
    fontFamily: 'Exo 2 !important',
    transition: '0.1s all !important',
    // border: "0.5px solid rgb(255 255 255 / 50%) !important",
    borderRadius: '8px !important',
    background: '#000 !important',
    '&:hover': {
      background: '#000 !important',
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed !important',
      pointerEvents: 'auto !important',
      color: 'rgb(255 255 255 / 38%) !important',
      '&:hover': {
        opacity: '1',
      },
    },
  },
  error: {
    marginTop: '0.2rem !important',
    fontSize: '13px !important',
    color: 'red !important',
    fontFamily: 'Raleway !important',
    textAlign: 'start',
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UpdateKycModal = ({ handleClose, open, getGirlData, viewData, handleCloseMenu }) => {
  const classes = useStyles();
  const [sucess, setSuccess] = React.useState(false);
  const [kyc, setKyc] = React.useState('');
  const [loader, setLoader] = React.useState(false);

  const KycUpdateFunc = async () => {
    setLoader(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.post(`${API_URL}/api/users/kyc-update`, { id: viewData?.id, kycStatus: kyc }, config);
      if (data.status === 200) {
        setLoader(false);
        setKyc("")
        getGirlData();
        handleClose();
        handleCloseMenu();
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    } catch (error) {
      setLoader(false);
      console.log('catch', error);
    }
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiPaper-root': {
            width: '800px !important',
            background: '#1d1e1f',
            color: '#fff',
            border: ' 1px solid #ffffff59',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #ffffff59',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div style={{ color: '#fff9e6' }}>Update KYC</div>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            p: '1rem',
            '& p': {
              fontSize: '15px',
              lineHeight: '22.24px',
              fontWeight: '400',
              color: 'rgba(255, 255, 255, 0.8)',
            },
          }}
        >
          <Box>
            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Update KYC:</Typography>
            <select
              value={kyc}
              id="filter"
              name="filter"
              onChange={(e) => setKyc(e.target.value)}
              style={{
                padding: '12px 5px',
                width: '100%',
                background: '',
                fontSize: '16px',
                borderRadius: '5px',
                border: '1px solid #CCCCCC',
                color: '#000',
                margin: '10px 0',
              }}
            >
              <option value={''}>--select--</option>
              <option value="Approved">Approve</option>
              <option value="Not Approved">Reject</option>
            </select>
          </Box>
          <Box
            sx={{
              mt: '1rem',
              textAlign: 'center',
              py: '1rem',
            }}
          >
            <Button className={classes.btn} type="submit" disabled={kyc === ''} onClick={KycUpdateFunc}>
              {loader ? 'Submitting...' : ' Update KYC'}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>

      {/* //success modal  */}
      <BootstrapDialog
        onClose={() => setSuccess(false)}
        aria-labelledby="customized-dialog-title"
        open={sucess}
        sx={{
          '& .MuiPaper-root': {
            width: '500px !important',
            background: '#1d1e1f',
            color: '#fff',
            border: ' 1px solid #ffffff59',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #ffffff59',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setSuccess(false)}>
          <div style={{ color: 'green' }}>Success</div>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            p: '1rem',
            '& p': {
              fontSize: '15px',
              lineHeight: '22.24px',
              fontWeight: '400',
              color: 'rgba(255, 255, 255, 0.8)',
            },
          }}
        >
          <Box sx={{ p: '2rem 1rem', textAlign: 'center', color: 'green' }}>KYC Updated Successfully..</Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default UpdateKycModal;
