import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Button } from '@mui/material';

export const ConnectWalletBtn = () => {
  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');
        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button
                    sx={{
                      border: '2px solid #6373811f',
                      padding: '12px 20px',
                      background: 'rgba(145, 158, 171, 0.16)',
                      cursor: 'pointer',
                      borderRadius: '10px',
                      lineHeight: 'normal',
                      fontWeight: '500',
                      color: '#060a15',
                      fontSize: !connected ? '16px' : '15px',
                      textTransform: 'capitalize',
                      letterSpacing: 'initial',
                    }}
                    onClick={openConnectModal}
                    type="button"
                  >
                    Connect
                  </Button>
                );
              }
              if (chain.unsupported) {
                return (
                  <>
                    <Button
                      sx={{
                        border: '2px solid #6373811f',
                        color: '#060a15',
                        padding: '12px 20px',
                        lineHeight: 'normal',
                        fontWeight: '500',
                        textTransform: 'capitalize',
                        background: 'rgba(145, 158, 171, 0.16)',
                        cursor: 'pointer',
                        borderRadius: '10px',
                        letterSpacing: 'initial',
                        fontSize: !connected ? '16px' : '15px',
                      }}
                      onClick={openChainModal}
                      type="button"
                    >
                      Wrong Network
                    </Button>
                  </>
                );
              }
              return (
                <div>
                  <Button
                    sx={{
                      border: '2px solid #6373811f',
                      padding: '12px 20px',
                      lineHeight: 'normal',
                      color: '#060a15',
                      backgroundSize: '100% 100%',
                      background: 'rgba(145, 158, 171, 0.16)',
                      cursor: 'pointer',
                      borderRadius: '10px',
                      textTransform: 'capitalize',
                      fontFamily: 'sans-serif',
                      fontWeight: '600',
                      fontSize: !connected ? '16px' : '15px',
                      letterSpacing: 'initial',
                    }}
                    className="conbutton cBtn"
                    onClick={openAccountModal}
                    type="button"
                  >
                    {/* {account.displayName} */}
                    {/* {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ''} */}
                    ({account.displayBalance}) {account.displayName}
                  </Button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
