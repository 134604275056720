import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { API_URL } from '../config/index';

const useStyles = makeStyles((theme) => ({
  bin1: {
    //   marginBottom: "0.5rem !important",
    width: '100%',
    border: '0.5px solid rgb(255 255 255 / 28%) !important',
    borderRadius: '8px ',
    background: '#1d1e1f !important',
    color: '#fff',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&:focus-Visible': {
      outline: 'none !important',
      border: 'none !important',
    },
    '&:hover': {
      border: 'none',
    },
    '& .MuiInputBase-root': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },

    '&:focus': {
      boxShadow: 'none !important',
      border: '0.5px solid #fff !important',
      borderRadius: '10px !important ',
    },
    '& input': {
      padding: '12px 15px',
      fontSize: '16px',
      borderRadius: '10px',
      fontWeight: '400',
      color: 'rgb(255 255 255 / 100%) !important',
      '&::placeholder': {
        color: 'rgb(255 255 255 / 70%) !important',
        fontSize: '15px',
        fontWeight: '400',
      },
    },
  },
  btn: {
    color: '#fff !important',
    lineHeight: '19.92px !important',
    fontSize: '14px !important',
    height: '45px',
    // width: '130px',
    padding: '10px 20px !important',
    fontWeight: '400 !important',
    fontFamily: 'Exo 2 !important',
    transition: '0.1s all !important',
    // border: "0.5px solid rgb(255 255 255 / 50%) !important",
    borderRadius: '8px !important',
    background: '#000 !important',
    '&:hover': {
      background: '#000 !important',
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed !important',
      pointerEvents: 'auto !important',
      color: 'rgb(255 255 255 / 38%) !important',
      '&:hover': {
        opacity: '1',
      },
    },
  },
  error: {
    marginTop: '0.2rem !important',
    fontSize: '13px !important',
    color: 'red !important',
    fontFamily: 'Raleway !important',
    textAlign: 'start',
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CommissionModal = ({ handleClose, open, getGirlData, viewData }) => {
  const [sucess, setSuccess] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      commission: '',
      // checkbox: false,
    },
    validationSchema: Yup.object({
      commission: Yup.string()
        .trim('Commission should not contain spaces').required('Required')
        // .when('checkbox', {
        //   is: true,
        //   then: Yup.string().required('Required'),
        //   otherwise: Yup.string(),
        // })
        ,
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm, errors }) => {
      // alert("Submitted")
      setLoader(true);
      const girlsRoute = `${API_URL}/api/users/update-comission`;
      //   setReferalErr(false);
      try {
        //   if (values.referredBy !== "") {
        //     const valid =  await checkValidity(values.referredBy)
        //     if(!valid){
        //       setReferalErr(true);
        //       return false
        //     }
        //   }
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,

          },
        };
        const res = await axios.post(
          girlsRoute,
          {
            commission: formik.values.commission,
            id: viewData?.id,
            // data: { wallet: address , ...values },
          },
          config
        );
        if (res.status === 200) {
          setLoader(false);
          getGirlData();
          handleClose();
          setSuccess(true);
        }
      } catch (err) {
        console.log(err);
        setLoader(false);
      }
    },
  });
  useEffect(() => {
    if (viewData?.commission != null) {
      formik.setValues({
        commission: viewData?.commission,
        checkbox: viewData?.commission != null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewData]);

  const classes = useStyles();

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiPaper-root': {
            width: '800px !important',
            background: '#1d1e1f',
            color: '#fff',
            border: ' 1px solid #ffffff59',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #ffffff59',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div style={{ color: '#fff9e6' }}>Update Commission</div>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            p: '1rem',
            '& p': {
              fontSize: '15px',
              lineHeight: '22.24px',
              fontWeight: '400',
              color: 'rgba(255, 255, 255, 0.8)',
            },
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box
              py="1rem"
              sx={{
                display: 'flex',
                gap: '0.5rem',
                flexWrap: 'wrap',
              }}
            >
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#fff',
                }}
              >
                NOTE: Please enter 0 if you want to remove individual commission for this user{' '}
                {/* <input
                  type="checkbox"
                  style={{ cursor: 'pointer' }}
                  id="yes"
                  name="checkbox"
                  value="yes"
                  checked={formik.values.checkbox} // Check based on a boolean value
                  onChange={
                    (e) => formik.setFieldValue('checkbox', e.target.checked) // Set the value to boolean
                  }
                  onBlur={formik.handleBlur}
                />{' '}
                <span htmlFor="yes">Yes</span>{' '}
                <input
                  type="checkbox"
                  style={{ cursor: 'pointer' }}
                  id="no"
                  name="checkbox"
                  value="no"
                  checked={!formik.values.checkbox} // Check based on a boolean value
                  onChange={
                    (e) => formik.setFieldValue('checkbox', !e.target.checked) // Set the value to boolean
                  }
                  onBlur={formik.handleBlur}
                />{' '}
                <span htmlFor="no">No</span> */}
              </span>
            </Box>

            {formik.touched.checkbox && formik.errors.checkbox && (
              <Typography variant="body1" className={classes.error}>
                {formik.errors.checkbox}
              </Typography>
            )}
            {/* {formik.values.checkbox === true && ( */}
              <Box color={'#fff'} pb="0.5rem">
                <Typography>Commission (in %)*</Typography>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="commission"
                  name="commission"
                  type="text"
                  placeholder="commission"
                  error={viewData?.commission}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.commission}
                  className={classes.bin1}
                  sx={{
                    background: 'rgba(42, 41, 52, 1)',
                    color: '#fff',
                  }}
                  variant="standard"
                />
                {formik.touched.commission && formik.errors.commission && (
                  <Typography variant="body1" className={classes.error}>
                    {formik.errors.commission}
                  </Typography>
                )}
              </Box>
            {/* )} */}
           {/* {formik.values.checkbox === true &&  */}
           <Box
              sx={{
                mt: '1rem',
                textAlign: 'center',
                py: '1rem',
              }}
            >
              <Button className={classes.btn} type="submit">
                {loader ? 'Submitting...' : ' Update Commission'}
              </Button>
            </Box>
            {/* } */}
          </form>
        </DialogContent>
      </BootstrapDialog>

      {/* //success modal  */}
      <BootstrapDialog
        onClose={() => setSuccess(false)}
        aria-labelledby="customized-dialog-title"
        open={sucess}
        sx={{
          '& .MuiPaper-root': {
            width: '500px !important',
            background: '#1d1e1f',
            color: '#fff',
            border: ' 1px solid #ffffff59',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #ffffff59',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setSuccess(false)}>
          <div style={{ color: 'green' }}>Success</div>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            p: '1rem',
            '& p': {
              fontSize: '15px',
              lineHeight: '22.24px',
              fontWeight: '400',
              color: 'rgba(255, 255, 255, 0.8)',
            },
          }}
        >
          <Box sx={{ p: '2rem 1rem', textAlign: 'center', color: 'green' }}>Commission Updated Successfully..</Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default CommissionModal;
