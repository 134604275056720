import PropTypes from 'prop-types';
import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../../config';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  onRequestSort: PropTypes.func,
  onStatusFilterChange: PropTypes.func,
  setGirlData: PropTypes.func,
  statusFilter: PropTypes.string,
};

export default function UserListHead({
  type,
  order,
  orderBy,
  headLabel,
  onRequestSort,
  onStatusFilterChange = () => {}, // Default function
  setListData = () => {}, // Default function
  statusFilter,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleStatusFilterChange = (event) => {
    onStatusFilterChange(event.target.value);
  };
  const handleFilterByStatus = async (searchQuery) => {

    // try {
    //   const response = await axios({
    //     method: 'GET',
    //     url:
    //       searchQuery !== 'All'
    //         ? `${API_URL}/api/users?filters[user_type][$eq]=${type}&filters[status][$eq]=${searchQuery}&populate=*`
    //         : `${API_URL}/api/users?filters[user_type][$eq]=${type}&populate=*`,
    //     headers: {
    //       'Accept-Encoding': 'application/json',
    //       'Authorization': `Bearer ${localStorage.getItem('token')}`,
    //     },
    //   });
    //   setListData(response.data);
    // } catch (error) {
    //   console.error('Error fetching girl data:', error);
    //   // Handle the error appropriately
    // }
  };
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.filter ? (  
              <FormControl
                sx={{
                  width: '120px',
                  border: 'none',
                  '& fieldset': {
                    border: 'none',
                  },
                }}
              >
                <InputLabel sx={{ color: '#637381 !important' }}>Collection Status</InputLabel>
                <Select
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={{ border: 'none' }} // Add this line to remove the border of the Select component
                >
                  <MenuItem value="All" onClick={() => handleFilterByStatus('All')}>
                    All
                  </MenuItem>
                  <MenuItem value="Approved" onClick={() => handleFilterByStatus('Approved')}>
                    Approved
                  </MenuItem>
                  <MenuItem value="Incomplete" onClick={() => handleFilterByStatus('Incomplete')}>
                    Incomplete
                  </MenuItem>
                  <MenuItem value="Pending" onClick={() => handleFilterByStatus('Pending')}>
                    Pending
                  </MenuItem>
                </Select>
              </FormControl>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
