import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, TextField, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import axios from 'axios';
import { API_URL } from '../../config';

const useStyles = makeStyles((theme) => ({
    bin1: {
      marginBottom: "0.5rem !important",
      width: "100%",
      border: "0.5px solid rgb(255 255 255 / 28%) !important",
      borderRadius: "8px ",
      background: "#1d1e1f !important",
      color: "#fff",
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&:focus-Visible": {
        outline: "none !important",
        border: "none !important",
      },
      "&:hover": {
        border: "none",
      },
      "& .MuiInputBase-root": {
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
      },
  
      "&:focus": {
        boxShadow: "none !important",
        border: "0.5px solid #fff !important",
        borderRadius: "10px !important ",
      },
      "& input": {
        padding: "12px 15px",
        fontSize: "16px",
        borderRadius: "10px",
        fontWeight: "400",
        color: "rgb(255 255 255 / 100%) !important",
        "&::placeholder": {
          color: "rgb(255 255 255 / 70%) !important",
          fontSize: "15px",
          fontWeight: "400",
        },
   
      },
    },
    btn: {
      color: "#fff !important",
      lineHeight: "19.92px !important",
      fontSize: "14px !important",
      height: "45px",
      width: "40%",
      fontWeight: "400 !important",
      fontFamily: "Exo 2 !important",
      transition: "0.1s all !important",
      // border: "0.5px solid rgb(255 255 255 / 50%) !important",
      borderRadius: "8px !important",
      background: "#000 !important",
      "&:hover": {
        background: "#000 !important",
      },
      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        pointerEvents: "auto !important",
        color: "rgb(255 255 255 / 38%) !important",
        "&:hover": {
          opacity: "1",
        },
      },
    },
  }));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ViewApproveModal = ({ handleClose, open, viewData,getRequestList,setShowSnackbar,handleCloseMenu}) => {
    const [txnId,setTxnId] = useState("")
    const [txnDate,setTxnDate] = useState("")
    const classes = useStyles();
    const profileApproved = async () => {
        const config = {
          headers: {
            // authorization: localStorage.getItem(JWT),
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
        try {
          const data = await axios.put(`${API_URL}/api/approve-withdrawal`, {
            userid:viewData?.attributes?.userid,
            id:viewData?.id,
            txn_date:txnDate,
            txn_id:txnId
          }, config);
          if (data.data.data.status === 'Approved') {
              getRequestList()
            setShowSnackbar(true);
            handleClose();
            handleCloseMenu()
            setTimeout(() => {
              setShowSnackbar(false);
            }, 2000);
          }
        } catch (error) {
          console.log('catch', error);
        }
      };
      useEffect(()=>{
        if(!open){
            setTxnId("")   
            setTxnDate("")   
        }
      },[open])
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiPaper-root': {
            width: '500px !important',
            background: '#1d1e1f',
            color: '#fff',
            border: ' 1px solid #ffffff59',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #ffffff59',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div style={{ color: '#fff9e6' }}>Approve</div>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            p: "1rem",
          }}
        >
          <Box color={"#fff"} pb="0.5rem">
            <Typography
              sx={{
                fontSize: "15px",
                lineHeight: "22.24px",
                fontWeight: "400",
                color: "rgba(255, 255, 255, 0.8)",
                pb: "0.2rem",
              }}
            >
              Transaction ID
            </Typography>
            <TextField
              autoComplete="off"
              fullWidth
              type='text'
              value={txnId}
              onChange={(e)=>setTxnId(e.target.value)}
            placeholder='Enter Transaction ID'
              className={classes.bin1}
              sx={{
                background: "rgba(42, 41, 52, 1)",
                color: "#fff",
              }}
              
              variant="standard"
            />
          </Box>
          <Box color={"#fff"}>
            <Typography
              sx={{
                fontSize: "15px",
                lineHeight: "22.24px",
                fontWeight: "400",
                color: "rgba(255, 255, 255, 0.8)",
                pb: "0.2rem",
              }}
            >
              Transaction Date
            </Typography>
            <TextField
              autoComplete="off"
              fullWidth
              type='date'
              value={txnDate}
              onChange={(e)=>setTxnDate(e.target.value)}
              className={classes.bin1}
              sx={{
                background: "rgba(42, 41, 52, 1)",
                color: "#fff",
                "& input[type='date']::-webkit-calendar-picker-indicator": {
            filter: "invert(1) saturate(0)",
            cursor: "pointer",
          },
              }}
              
              variant="standard"
            />
    
            <Box
              sx={{
                mt:"1rem",
                textAlign: "center",
                py: "1rem",
              }}
            >
              <Button
                onClick={profileApproved}
                // disabled={
                //   errMsg || withdrwalAmount === null || withdrwalAmount <= 20 
                //   || request?.length>0
                // }
                className={classes.btn}
              >
                Approve
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ViewApproveModal;
