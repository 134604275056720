import React from 'react';
import ReactDOM from 'react-dom/client';
import { Buffer } from 'buffer';

import '@rainbow-me/rainbowkit/styles.css';

// import zkicon from '../public/favicon/favicon-32x32.png'
import { WagmiConfig, createClient, defaultChains, configureChains, chain, useToken } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { getDefaultWallets, RainbowKitProvider, AvatarComponent } from '@rainbow-me/rainbowkit';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import App from './App';

// ----------------------------------------------------------------------
const zksync = {
  id: 324,
  name: 'ZkSync Mainnet',
  network: 'ZkSync Era Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: 'https://mainnet.era.zksync.io',
  },
  // iconUrl: zkicon,
  blockExplorers: {
    default: { name: 'zkSync Era Explorer', url: 'https://explorer.zksync.io' },
  },
  testnet: false,
};
const Mumbai = {
  id: 80001,
  name: 'Mumbai',
  network: 'Mumbai Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'MATIC',
    symbol: 'MATIC',
  },
  rpcUrls: {
    default: 'https://polygon-mumbai-bor.publicnode.com	',
    // default: 'https://rpc-mumbai.maticvigil.com',
  },
  blockExplorers: {
    default: { name: 'Polygonscan', url: 'https://mumbai.polygonscan.com/' },
  },
  testnet: true,
};

const bsc = {
  id: 56,
  name: 'BSC Mainnet',
  network: 'Binance Smart Chain',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: 'https://bsc-dataseed.binance.org/',
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://bscscan.com' },
  },
  testnet: false,
};
const bscTest = {
  id: 97,
  name: 'BSC Testnet',
  network: 'Binance Smart Chain Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'tBNB',
    symbol: 'tBNB',
  },
  iconUrl: '',
  rpcUrls: {
    default: 'https://data-seed-prebsc-1-s2.binance.org:8545',
  },
  blockExplorers: {
    default: { name: 'BscScan TestNet', url: 'https://testnet.bscscan.com' },
  },
  testnet: true,
};
const CustomAvatar = ({ address, ensImage, size }) => {
  return ensImage ? (
    <img src={ensImage} width={size} height={size} style={{ borderRadius: 999 }} alt="" />
  ) : (
    <img
      style={{ boxShadow: '0px 0px 8px -2px #000', padding: '10px', borderRadius: '50%' }}
      src="/assets/images/avatars/avatar_default.jpg"
      alt=""
    />
  );
};

const myCustomTheme = {
  blurs: {
    modalOverlay: '...',
  },
  colors: {
    accentColor: '#0066FF',
    accentColorForeground: '#060a15',
    actionButtonBorder: '...',
    actionButtonBorderMobile: '...',
    actionButtonSecondaryBackground: '...',
    closeButton: '#060a15',
    closeButtonBackground: '...',
    connectButtonBackground: '...',
    connectButtonBackgroundError: '...',
    connectButtonInnerBackground: '...',
    connectButtonText: '...',
    connectButtonTextError: '...',
    connectionIndicator: '...',
    downloadBottomCardBackground: '...',
    downloadTopCardBackground: '...',
    error: '...',
    generalBorder: '...',
    generalBorderDim: '...',
    menuItemBackground: '...',
    modalBackdrop: '#000000c9',
    modalBackground: '#F9FAFB',
    modalBorder: '#637381',
    modalText: '#060a15',
    modalTextDim: '...',
    modalTextSecondary: '#060a15',
    profileAction: '...',
    profileActionHover: '...',
    profileForeground: '...',
    selectedOptionBorder: '...',
    standby: '...',
  },
  fonts: {
    body: 'Public Sans,sans-serif',
  },
  radii: {
    actionButton: '...',
    connectButton: '...',
    menuButton: '...',
    modal: '16px',
    modalMobile: '16px',
  },
  shadows: {
    connectButton: '...',
    dialog: '0px 0px 12px -2px #000',
    profileDetailsAction: '...',
    selectedOption: '...',
    selectedWallet: '...',
    walletLogo: '...',
  },
};

const { chains, provider, webSocketProvider } = configureChains(
  [Mumbai],
  [
    publicProvider(),
    jsonRpcProvider({
      priority: 0,
      rpc: (chain) => {
        if (chain?.id === zksync?.id) {
          return { http: chain?.rpcUrls?.default };
        }
        return null;
      },
    }),
  ]
);
const { connectors } = getDefaultWallets({
  appName: 'Dream-girl | Admin',
  chains,
});
const client = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
});
// polyfill Buffer for client
if (!window.Buffer) {
  window.Buffer = Buffer;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <WagmiConfig client={client}>
      <RainbowKitProvider avatar={CustomAvatar} theme={myCustomTheme} chains={chains} modalSize="compact" coolMode>
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
