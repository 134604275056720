import { Avatar, IconButton, MenuItem, Popover, Stack, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import axios from 'axios';
import Label from '../components/label';
import Iconify from '../components/iconify';
import { ConnectWalletBtn } from '../components/ConnectWallet';
import ViewModal from './ViewModal/ViewModal';
import { IPFS_URL, EXPLORER_URL, API_URL, SYMBOL } from '../config';
import WithdrawalViewModal from './ViewModal/WithdrawalViewModal';
import ViewApproveModal from './ViewModal/ViewApproveModal';

const WithdrawalListRow = ({
  row,
  selected,
  setShowSnackbar,
  setShowSnackbarRej,
  getRequestList
 
}) => {
  const { id, firstName, email, wallet, approved, requestForApproval, avatarUrl, ipfs, status, attributes } = row;
  const selectedUser = selected.indexOf(firstName) !== -1;
  const { address, isConnected } = useAccount();
  const [userData, setUserData] = useState([]);
  const [earning, setEarning] = useState([]);
  const [view, setView] = useState(false);
  const handleClickOpen = () => {
    setView(true);
  };
  const handleClickClose = () => {
    setView(false);
  };
  const [approveModal, setApproveModal] = useState(false);
  const handleApproveOpen = () => {
    setApproveModal(true);
  };
  const handleApproveClose = () => {
    setApproveModal(false);
  };
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const getUser = async (next) => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.get(`${API_URL}/api/users/${attributes?.userid}`, config);
      setUserData(data.data);
    } catch (error) {
      console.log('catch', error);
    }
  };
  const getEarning = async (next) => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.get(`${API_URL}/api/get-all-earning-by-userid/${attributes?.userid}`, config);
      setEarning(data.data.balance);
    } catch (error) {
      console.log('catch', error);
    }
  };
  useEffect(() => {
    getUser();
    getEarning()
  }, [attributes?.userid]);

  const profileRejected = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.put(`${API_URL}/api/reject-withdrawal`, { userid:attributes?.userid }, config);
      if (data.data.data.status === 'Rejected') {
        getRequestList();
        setShowSnackbarRej(true);
        handleCloseMenu();
        setTimeout(() => {
          setShowSnackbarRej(false);
        }, 2000);
      }
    } catch (error) {
      console.log('catch', error);
    }
  };

  return (
    <>
      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
        <TableCell align="left">{id}</TableCell>

        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={firstName} src={avatarUrl} />
            <Typography variant="subtitle2" noWrap>
              {userData?.firstName} {userData?.lastName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="left">{userData?.email}</TableCell>
        <TableCell align="left">$ {attributes?.amount}</TableCell>

        <TableCell align="left">{new Date(attributes?.createdAt).toDateString()}</TableCell>
        <TableCell align="left">{attributes?.method==="Crypto"?"ERC 20 Crypto Wallet Address":attributes?.method}</TableCell>

        <TableCell align="left">
        <Label color={attributes?.status === 'Requested' || attributes?.status === 'Rejected' ? 'error' : 'success'}>
  {attributes?.status}
</Label>

        </TableCell>

        <TableCell align="right">
          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
     {attributes?.status === 'Requested' &&   <MenuItem onClick={handleApproveOpen}>
          <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
          Approve
        </MenuItem>}
      {attributes?.status === 'Requested' &&  <MenuItem sx={{ color: 'error.main' }} onClick={profileRejected}>
          <Iconify icon={'ant-design:close-circle-outlined'} sx={{ mr: 2 }} />
          Reject
        </MenuItem>}
        <MenuItem onClick={handleClickOpen}>
          <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
          View
        </MenuItem>
       
        <WithdrawalViewModal open={view} handleClose={handleClickClose} viewData={row} userData={userData} earning={earning}/>
        <ViewApproveModal open={approveModal} handleClose={handleApproveClose} viewData={row} setShowSnackbar={setShowSnackbar} handleCloseMenu={handleCloseMenu} getRequestList={getRequestList}/>
      </Popover>
    </>
  );
};

export default WithdrawalListRow;
