import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
  import OutlinedInput from '@mui/material/OutlinedInput';
  import InputLabel from '@mui/material/InputLabel';
  import MenuItem from '@mui/material/MenuItem';
  import FormControl from '@mui/material/FormControl';
  import ListItemText from '@mui/material/ListItemText';
  import Select from '@mui/material/Select';
  import Checkbox from '@mui/material/Checkbox';
import { API_URL } from '../config/index';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  bin1: {
    //   marginBottom: "0.5rem !important",
    width: '100%',
    border: '0.5px solid rgb(255 255 255 / 28%) !important',
    borderRadius: '8px ',
    background: '#1d1e1f !important',
    color: '#fff',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&:focus-Visible': {
      outline: 'none !important',
      border: 'none !important',
    },
    '&:hover': {
      border: 'none',
    },
    '& .MuiInputBase-root': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },

    '&:focus': {
      boxShadow: 'none !important',
      border: '0.5px solid #fff !important',
      borderRadius: '10px !important ',
    },
    '& input': {
      padding: '12px 15px',
      fontSize: '16px',
      borderRadius: '10px',
      fontWeight: '400',
      color: 'rgb(255 255 255 / 100%) !important',
      '&::placeholder': {
        color: 'rgb(255 255 255 / 70%) !important',
        fontSize: '15px',
        fontWeight: '400',
      },
    },
  },
  btn: {
    color: '#fff !important',
    lineHeight: '19.92px !important',
    fontSize: '14px !important',
    height: '45px',
    width: '130px',
    fontWeight: '400 !important',
    fontFamily: 'Exo 2 !important',
    transition: '0.1s all !important',
    // border: "0.5px solid rgb(255 255 255 / 50%) !important",
    borderRadius: '8px !important',
    background: '#000 !important',
    '&:hover': {
      background: '#000 !important',
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed !important',
      pointerEvents: 'auto !important',
      color: 'rgb(255 255 255 / 38%) !important',
      '&:hover': {
        opacity: '1',
      },
    },
  },
  error: {
    marginTop: '0.2rem !important',
    fontSize: '13px !important',
    color: 'red !important',
    fontFamily: 'Raleway !important',
    textAlign: 'start',
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddGirlModal = ({ handleClose, open, getGirlData, viewData }) => {
  const steps = ['Profile Info', 'Profile Look'];

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const [status, setStatus] = React.useState('idle');
  const [sucess, setSuccess] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [errorText, setErrorText] = React.useState(null);
  const [phoneNumErr, setPhoneNumErr] = React.useState(null);
  const [ethnicity, setEthnicity] = React.useState([]);
  const [bodyTypeValue, setBodyTypeValue] = React.useState([]);
  const [hairColorValue, setHairColorValue] = React.useState('');
  const [eyeColorValue, setEyeColorValue] = React.useState('');
  const ethnicityOptions = [
    'Caucasian/White',
    'Asian',
    'Spanish/Latino',
    'Black/ African',
    'Southeast Asian / Indian',
    'Middle Eastern',
    'Mixed',
    'Native',
  ];
  const bodyType = ['Skinny', 'Slim', 'Fit & Toned', 'Muscular', 'Slim Thick', 'Curves For Days', 'BBW'];
  const hairColor = ['Blond', 'Brunette', 'Black', 'Brown', 'Red Head', 'Colorful AF', 'Other'];
  const eyeColor = ['Blue', 'Green', 'Brown', 'Hazel', 'Other'];
  useEffect(() => {
    formik.resetForm();
    setActiveStep(0);
  }, [open]);
  const formik = useFormik({
    initialValues: {
      referredBy: viewData?.username || '',
      username: '',
      firstName: '',
      lastName: '',
      password: '',
      email: '',
      phoneNumber: '',
      instagram_handle: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .trim('Username should not contain spaces')
        .min(6, 'Username must be at least 6 characters')
        .max(16, 'Username can be at most 16 characters')
        .matches('^[a-zA-Z_0-9]{6,16}$', 'Username should contain letters, number or underscores')
        .required('Required'),
      password: Yup.string()
        .trim('Password should not contain spaces')
        .min(8, 'Password must be at least 8 characters')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Password should contain at least one uppercase letter, one lowercase letter, one number, one special character.'
        )
        // .matches(
        //   "^[a-zA-Z0-9_]{6,16}$",
        //   "Password should contain letters, number or underscores"
        // )
        .required('Required'),
      firstName: Yup.string().trim('Fisrt Name should not contain spaces').required('Required'),
      lastName: Yup.string().trim('Last Name should not contain spaces').required('Required'),
      email: Yup.string().trim('Email should not contain spaces').email('Invalid email address').required('Required'),
      phoneNumber: Yup.string().trim('Phone number should not contain spaces'),

      instagram_handle: Yup.string().trim('Social media handle should not contain spaces'),
      referredBy: Yup.string().trim('Referral code should not contain spaces'),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm, errors }) => {
      // alert("Submitted")
      handleNext();
    },
  });
  const addGirlFunc = async () => {
    const girlsRoute = `${API_URL}/api/auth/local/register`;
    //   setReferalErr(false);
    try {
      //   if (values.referredBy !== "") {
      //     const valid =  await checkValidity(values.referredBy)
      //     if(!valid){
      //       setReferalErr(true);
      //       return false
      //     }
      //   }

      const res = await axios.post(girlsRoute, {
        user_type: 'Girl',
        ...formik.values,
        confirmed: true,
        status: 'Pending',
        ethnicity: ethnicity.join(','), // Convert array to string
        bodyType: bodyTypeValue.join(','), // Convert array to string
        hairColor: hairColorValue,
        eyeColor: eyeColorValue,

        // data: { wallet: address , ...values },
      });
      if (res.status === 200) {
        setStatus('success');
        getGirlData();
        handleClose();
        formik.resetForm();
        setSuccess(true);
        setEthnicity([]);
        setEyeColorValue('');
        setHairColorValue('');
        setBodyTypeValue([]);
      } else {
        // setErrorArray(res.data.error.message);
        setErrorText(res.data.error.message);
      }
    } catch (err) {
      setPhoneNumErr(err.response.data.error.details.errors?.[0].path?.[0] === 'phoneNumber');
      setErrorText(err.response.data.error.message);
    }
  };
  const classes = useStyles();
  const handleHairColor = (event) => {
    setHairColorValue(event.target.value);
  };
  const handleEyeColor = (event) => {
    setEyeColorValue(event.target.value);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
  
    // Check if the selected value length is greater than 2
    if (value.length > 2) {
      // If more than 2 options are selected, keep only the first two selections
      setEthnicity(value.slice(0, 2));
    } else {
      setEthnicity(value);
    }
  };

  const handleBodyTypeValue = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length > 2) {
      // If more than 2 options are selected, keep only the first two selections
      setBodyTypeValue(value.slice(0, 2));
    } else {
      setBodyTypeValue(value);
    }
    // setBodyTypeValue(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value
    // );
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiPaper-root': {
            width: '800px !important',
            background: '#1d1e1f',
            color: '#fff',
            border: ' 1px solid #ffffff59',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #ffffff59',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div style={{ color: '#fff9e6' }}>Add Girl</div>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            p: '1rem',
            '& p': {
              fontSize: '15px',
              lineHeight: '22.24px',
              fontWeight: '400',
              color: 'rgba(255, 255, 255, 0.8)',
            },
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Stepper
              activeStep={activeStep}
              sx={{
                borderRadius: '5px',
                p: '1rem 0rem',
                width: { sm: '90%', xs: '100%' },
                m: '0 auto',
                '& .MuiStepConnector-line': {
                  marginLeft: '4px',
                  // borderColor:"#EED37F",
                  borderTopWidth: '2px',
                  my: '0.5rem',
                  // display:"none"
                },
                '& .MuiStepLabel-label': {
                  fontSize: '16px',
                  fontWeight: '600',
                  // fontFamily: "JosefinSansRegular",
                },
                '& .MuiStepLabel-label.Mui-active': {
                  fontSize: '16px',
                  fontWeight: '600',
                  // fontFamily: "JosefinSansRegular",
                  color: '#fff',
                },
                '& .MuiStepLabel-label.Mui-completed': {
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#fff',
                  // fontFamily: "JosefinSansRegular",
                },
                '& .MuiStepIcon-root.Mui-active': {
                  // color: "#EED37F",
                },
                '& .MuiStepIcon-root.Mui-completed': {
                  color: 'green',
                },
              }}
              // orientation="vertical"
            >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step
                    key={label}
                    {...stepProps}
                    sx={{
                      display: 'flex',
                      alignItems: 'self-start',
                      paddingRight: '0',
                      color: '#fff',
                      // paddingLeft: "5px",
                    }}
                  >
                    <StepLabel
                      {...labelProps}
                      sx={{ display: 'flex', alignItems: 'center', gap: '5px', flexDirection: 'column' }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === 0 && (
              <>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography>Username*</Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="username"
                    name="username"
                    type="text"
                    placeholder="username"
                    error={formik.errors.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username}
                    className={classes.bin1}
                    sx={{
                      background: 'rgba(42, 41, 52, 1)',
                      color: '#fff',
                    }}
                    variant="standard"
                  />
                  {formik.touched.username && formik.errors.username && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.username}
                    </Typography>
                  )}
                </Box>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography>Password*</Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="password"
                    error={formik.errors.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className={classes.bin1}
                    sx={{
                      background: 'rgba(42, 41, 52, 1)',
                      color: '#fff',
                    }}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="start"
                          >
                            {!showPassword ? (
                              <VisibilityOff sx={{ color: '#fff', cursor: 'pointer' }} />
                            ) : (
                              <Visibility sx={{ color: '#fff', cursor: 'pointer' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    //     endAdornment={

                    // }
                  />
                  {formik.touched.password && formik.errors.password && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.password}
                    </Typography>
                  )}
                </Box>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography>First Name*</Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    error={formik.errors.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    className={classes.bin1}
                    sx={{
                      background: 'rgba(42, 41, 52, 1)',
                      color: '#fff',
                    }}
                    variant="standard"
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.firstName}
                    </Typography>
                  )}
                </Box>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography>Last Name*</Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    error={formik.errors.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    className={classes.bin1}
                    sx={{
                      background: 'rgba(42, 41, 52, 1)',
                      color: '#fff',
                    }}
                    variant="standard"
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.lastName}
                    </Typography>
                  )}
                </Box>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography>Email*</Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    placeholder="name@example.com"
                    error={formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className={classes.bin1}
                    sx={{
                      background: 'rgba(42, 41, 52, 1)',
                      color: '#fff',
                    }}
                    variant="standard"
                  />

                  {formik.touched.email && formik.errors.email && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.email}
                    </Typography>
                  )}
                </Box>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography>Phone Number*</Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    placeholder="Your Phone Number"
                    error={formik.errors.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                    className={classes.bin1}
                    sx={{
                      background: 'rgba(42, 41, 52, 1)',
                      color: '#fff',
                    }}
                    variant="standard"
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.phoneNumber}
                    </Typography>
                  )}
                </Box>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography>Referred By</Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="referredBy"
                    name="referredBy"
                    type="text"
                    placeholder="referral code"
                    error={formik.errors.referredBy}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.referredBy}
                    className={classes.bin1}
                    sx={{
                      background: 'rgba(42, 41, 52, 1)',
                      color: '#fff',
                    }}
                    variant="standard"
                  />
                  {formik.touched.referredBy && formik.errors.referredBy && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.referredBy}
                    </Typography>
                  )}
                </Box>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography>Instagram Handle</Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="instagram_handle"
                    name="instagram_handle"
                    type="text"
                    placeholder="Your Instagram Handle"
                    error={formik.errors.instagram_handle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.instagram_handle}
                    className={classes.bin1}
                    sx={{
                      background: 'rgba(42, 41, 52, 1)',
                      color: '#fff',
                    }}
                    variant="standard"
                  />
                  {formik.touched.instagram_handle && formik.errors.instagram_handle && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.instagram_handle}
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    mt: '1rem',
                    textAlign: 'center',
                    py: '1rem',
                  }}
                >
                  <Button className={classes.btn} type="submit">
                    Next
                  </Button>
                </Box>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography pb={'0.5rem'}>Ethnicity</Typography>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-checkbox-label">Ethnicity</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      placeholder="Ethnicity"
                      multiple
                      value={ethnicity}
                      onChange={handleChange}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      sx={{
                        color: '#fff',
                      }}
                      input={<OutlinedInput label="Ethnicity" />}
                    >
                      {ethnicityOptions.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={ethnicity?.includes(name)} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography pb={'0.5rem'}>Body type</Typography>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-checkbox-label">Body type</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      placeholder="Body type"
                      multiple
                      value={bodyTypeValue}
                      sx={{
                        color: '#fff',
                      }}
                      onChange={handleBodyTypeValue}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                      input={<OutlinedInput label="Body type" />}
                    >
                      {bodyType.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={bodyTypeValue?.includes(name)} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography pb={'0.5rem'}>Hair color</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Hair color</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={hairColorValue}
                      label="Hair color"
                      onChange={handleHairColor}
                      sx={{
                        color: '#fff',
                      }}
                    >
                      {hairColor?.map((e, index) => (
                        <MenuItem value={e} key={index}>
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box color={'#fff'} pb="0.5rem">
                  <Typography pb={'0.5rem'}>Eye color</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Eye color</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={eyeColorValue}
                      label="Eye color"
                      sx={{
                        color: '#fff',
                      }}
                      onChange={handleEyeColor}
                    >
                      {eyeColor?.map((e, index) => (
                        <MenuItem value={e} key={index}>
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errorText && (
                    <Box sx={{ p: '1rem', display: 'flex' }}>
                      <Typography sx={{ color: 'red !important', fontSize: '1rem' }}>
                        {phoneNumErr ? 'Phone Number must be unique' : errorText}
                        <Typography
                          component={'span'}
                          // title="For More Info"
                        >
                          {' '}
                          {/* <InfoIcon
                        sx={{
                          fontSize: "1.2rem",
                          // cursor: "pointer",
                          verticalAlign: "sub",
                        }}
                      /> */}
                        </Typography>
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    mt: '1rem',
                    textAlign: 'center',
                    py: '1rem',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    gap: '1rem',
                  }}
                >
                  <Button className={classes.btn} type="button" onClick={handleBack}>
                    Back
                  </Button>
                  <Button className={classes.btn} type="button" onClick={addGirlFunc}>
                    Add Girl
                  </Button>
                </Box>
              </>
            )}
          </form>
        </DialogContent>
      </BootstrapDialog>

      {/* //success modal  */}
      <BootstrapDialog
        onClose={() => setSuccess(false)}
        aria-labelledby="customized-dialog-title"
        open={sucess}
        sx={{
          '& .MuiPaper-root': {
            width: '500px !important',
            background: '#1d1e1f',
            color: '#fff',
            border: ' 1px solid #ffffff59',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #ffffff59',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setSuccess(false)}>
          <div style={{ color: 'green' }}>Success</div>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            p: '1rem',
            '& p': {
              fontSize: '15px',
              lineHeight: '22.24px',
              fontWeight: '400',
              color: 'rgba(255, 255, 255, 0.8)',
            },
          }}
        >
          <Box sx={{ p: '2rem 1rem', textAlign: 'center', color: 'green' }}>Girl Added Successfully..</Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default AddGirlModal;
