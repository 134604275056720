import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../config/index';

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: '1rem', color: '#000' }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ConfirmationModal = ({ statusOpen, setStatusOpen, viewData, getGirlData, handleCloseMenu }) => {
  const [loading, setLoading] = useState(false);

  const accountApproval = async () => {
    setLoading(true);
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.put(`${API_URL}/api/users/account-approval`, { id: viewData.id }, config);
      // console.log(data);
      if (data.data.status === 'Approved') {
        getGirlData();
        setLoading(false);
        handleCloseMenu();
        setStatusOpen(false);
      }
      // console.log(data);
      // setEarningData(data.data);
    } catch (error) {
      setLoading(false);
      console.log('catch', error);
    }
  };

  return (
    <div>
      <Dialog
        onClose={() => setStatusOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={statusOpen}
        sx={{
          '& .MuiPaper-root': {
            width: '500px !important',
            maxWidth: '100% !important',
            background: '#F4F6F8',
            color: '#000',
            border: ' 1px solid #1d1e1f',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #1d1e1f',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontSize: '18px',
                lineHeight: '22.24px',
                fontWeight: '400',
                color: '#000',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                fontFamily: 'Exo 2 !important',
              }}
            >
              Approve
            </Typography>
            <CloseIcon
              sx={{ fontSize: '1.5rem', color: '#000', cursor: 'pointer' }}
              onClick={() => setStatusOpen(false)}
              title="Close"
            />
          </Box>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{
            p: '1rem',
          }}
        >
          <Box color={'#fff'}>
            <Typography
              sx={{
                fontSize: '18px',
                lineHeight: '22.24px',
                fontWeight: '400',
                color: '#000',
                fontFamily: 'Exo 2',
                pb: '2rem',
                pt: '1rem',
                textAlign: 'center',
              }}
            >
              Are you sure you want to Approve?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                pb: '1rem',
                '& button': {
                  color: '#fff !important',
                  lineHeight: '19.92px !important',
                  fontSize: '14px !important',
                  height: '45px',
                  width: '30%',
                  fontWeight: '400 !important',
                  fontFamily: 'Exo 2 !important',
                  transition: '0.1s all !important',
                  borderRadius: '8px !important',
                  '&.Mui-disabled': {
                    cursor: 'not-allowed !important',
                    pointerEvents: 'auto !important',
                    color: 'rgb(255 255 255 / 38%) !important',
                    '&:hover': {
                      opacity: '1',
                    },
                  },
                },
              }}
            >
              <Button
                disableRipple
                sx={{
                  background: 'green',
                  '&:hover': {
                    background: 'green !important',
                  },
                }}
                onClick={() => accountApproval()}
              >
                {loading ? 'Loading...' : 'Yes'}
              </Button>
              <Button
                sx={{
                  background: 'red',
                  '&:hover': {
                    background: 'red !important',
                  },
                }}
                disableRipple
                onClick={() => setStatusOpen(false)}
              >
                No
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmationModal;
