import { Avatar, IconButton, MenuItem, Popover, Stack, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import Iconify from '../components/iconify';
import ViewApproveModal from './ViewModal/ViewApproveModal';
import PartnerViewModal from './ViewModal/PartnerViewModal';

const PartnerListRow = ({
  row,
  selected,
 
}) => {
  const { id, avatarUrl, attributes } = row;
  const selectedUser = selected.indexOf(attributes?.companyName) !== -1;
  const { address, isConnected } = useAccount();
  const [view, setView] = useState(false);
  const handleClickOpen = () => {
    setView(true);
  };
  const handleClickClose = () => {
    setView(false);
  };
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };




  return (
    <>
      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
        <TableCell align="left">{id}</TableCell>

        <TableCell align="left">   {attributes?.companyName}</TableCell>
        <TableCell align="left">{attributes?.companyWebsite}</TableCell>

        <TableCell align="left">{attributes?.phone}</TableCell>
        <TableCell align="left">{attributes?.facebookHandle || "NA"}</TableCell>
        <TableCell align="left">{new Date(attributes?.createdAt).toDateString()}</TableCell>
        <TableCell align="right">
          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
    
        <MenuItem onClick={handleClickOpen}>
          <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
          View
        </MenuItem>
        <PartnerViewModal open={view} handleClose={handleClickClose} viewData={row}/>
          
      </Popover>
    </>
  );
};

export default PartnerListRow;
