import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import ViewImage from './ViewModal/ViewImage';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ImageList = ({ setImageModal, imageModal, images, status }) => {
  const [image, setImage] = useState([]);
  const [collectionImages, setCollectionImages] = useState([]);
  useEffect(() => {
    if (images) {
      const _image = images?.map((e, i) => {
        return {
          original: `${e.url}`,
          thumbnail: `${e.url}`,
          originalClass: 'custom-image',
          thumbnailClass: 'custom-thumbnail',
        };
      });
      setImage(_image);
    }
    // eslint-disable-next-line
  }, [images]);

  useEffect(() => {
    if (images) {
      const _collectionImages = images?.map((e, i) => {
        return {
          src: `${e.url}`,
          width: 200,
          height: 200,
          // caption: "After Rain (Jeshu John - designerspics.com)",
        };
      });
      setCollectionImages(_collectionImages);
    }
    // eslint-disable-next-line
  }, [images]);

  const handleSelect = (index) => {
    const nextImages = collectionImages.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setCollectionImages(nextImages);
  }


  const [index, setIndex] = useState(-1);
  const [prevIndex, setPrevIndex] = useState(null);
  const [nextIndex, setNextIndex] = useState(null);
  const [nextImage, setNextImage] = useState(null);
  const [prevImage, setPrevImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);


  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const handleClick = (_index, item) => {
    const currentImage = collectionImages?.[_index];
    setCurrentImage(currentImage)
    const nextIndex = (_index + 1) % collectionImages?.length;
    const nextImage = collectionImages?.[nextIndex] || currentImage;
    const prevIndex = (_index + collectionImages?.length - 1) % collectionImages?.length;
    setPrevIndex(prevIndex)
    setNextIndex(nextIndex)
    setNextImage(nextImage)

    const prevImage = collectionImages?.[prevIndex] || currentImage;
    setPrevImage(prevImage)
    setIndex(_index)
  }

  return (
    <div>
      <BootstrapDialog
        onClose={() => setImageModal(false)}
        aria-labelledby="customized-dialog-title"
        open={imageModal}
        sx={{
          '& .MuiPaper-root': {
            width: '600px !important',
            background: '#1d1e1f',
            color: '#fff',
            border: ' 1px solid #ffffff59',
          },
          '& .MuiDialogContent-root': {
            borderBottom: 'none',
            borderTop: ' 1px solid #ffffff59',
            '::-webkit-scrollbar': { width: '6px' },
            '::-webkit-scrollbar-track': { background: '#ffffff66' },
            '::-webkit-scrollbar-thumb': { background: '#3b3b3b' },
          },
          '& .MuiDialog-container': {
            backdropFilter: 'blur(8px)',
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => setImageModal(false)}>
          <div style={{ color: '#fff9e6' }}>Images</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {
            image?.length > 0 ?
              status === "Approved" ?
                <ImageGallery
                  items={image}
                  // showNav={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  disableSwipe
                  disableThumbnailSwipe
                  slideDuration={0}
                /> :
                <Gallery enableImageSelection images={collectionImages} onClick={handleClick} onSelect={handleSelect} />

              :
              <Box sx={{
                fontSize: "16px",
                fontWeight: "600",
                textAlign: "center",
                py: "2rem"
              }}>
                Image not found.
              </Box>
          }

         {
          currentImage &&
              <ViewImage  handleClose={() => setCurrentImage(null)} open={currentImage !== null} image={currentImage.src} />
         }


        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ImageList;
