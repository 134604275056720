import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Snackbar,
  Alert,
} from '@mui/material';

import axios from 'axios';
import { API_URL } from '../config';

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import WithdrawalListRow from './WithdrawalListRow';
import PartnerListRow from './PartnerListRow';

// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'companyName', label: 'Company Name', alignRight: false},
  { id: 'companyWebsite', label: 'Company Website', alignRight: false},
  { id: 'phone', label: 'phone', alignRight: false },
  { id: 'facebookHandle', label: 'Facebook Handle', alignRight: false },
  { id: 'createdAt', label: 'createdAt', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PartnerList() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [partnerList, setPartnerList] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showSnackbarRej, setShowSnackbarRej] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  useEffect(() => {
    getpartnerList();
  }, []);

  const getpartnerList = async () => {
    await axios({
      method: 'GET',
      url: `${API_URL}/api/business-partners`,
      headers: {
        'Accept-Encoding': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        // console.log(_data)
        setPartnerList(_data.data.data);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  console.log(partnerList);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = partnerList?.map((n) => n.companyName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, companyName) => {
    const selectedIndex = selected.indexOf(companyName);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, companyName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    setPage(0);
    setFilterName(searchQuery);
    if (!searchQuery) {
      getpartnerList();
      return;
    }
    const filteredData = partnerList.filter(
      (user) =>
        user?.companyName?.toLowerCase().includes(searchQuery) ||
        user?.email?.toLowerCase().includes(searchQuery) ||
        user?.companyWebsite?.toLowerCase().includes(searchQuery)
    );

    setPartnerList(filteredData);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - partnerList?.length) : 0;

  const filteredUsers = applySortFilter(partnerList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Snackbar open={showSnackbar}>
        <Alert severity={'success'} sx={{ backgroundColor: 'green', color: '#fff' }}>
          Approved Successfully
        </Alert>
      </Snackbar>
      <Snackbar open={showSnackbarRej}>
        <Alert severity={'error'} sx={{ backgroundColor: 'red', color: '#fff' }}>
          Reject Successfully
        </Alert>
      </Snackbar>
      <Helmet>
        <title> Partner List | Admin Panel </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Partner List
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={partnerList?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  onStatusFilterChange={setStatusFilter} 
                  statusFilter={statusFilter} 
                  setListData={setPartnerList}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, email, status, wallet, avatarUrl, ipfs } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <PartnerListRow
                        row={row}
                        selected={selected}
                        handleOpenMenu={handleOpenMenu}
                        handleClick={handleClick}
                        handleCloseMenu={handleCloseMenu}
                        open={open}
                        setShowSnackbar={setShowSnackbar}
                        setShowSnackbarRej={setShowSnackbarRej}
                        getpartnerList={getpartnerList}
                      />
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound || partnerList?.length===0  && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          {isNotFound && <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>}
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={partnerList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
