import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
 
// @mui
import {  Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { API_URL } from '../../../config';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [err, setErr] = useState(null);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required('Required')
        .trim('Email should not contain spaces')
        .email('Invalid email address')
        .required('Required'),
      password: yup.string().required('Required').trim('Email should not contain spaces'),
    }),
  });
  const {email , password } = formik.values ;

  const handleClick = () => {
    axios({
      method: 'post',
      url: `${API_URL}/api/auth/local`,
      data: {
        identifier: email,
        password,
      },
    })
      .then((res) => {
        localStorage.setItem('token', res.data.jwt);
        localStorage.setItem('name', `${res.data.user.firstName} ${res.data.user.lastName}`);
        navigate('/dashboard', { replace: true });
      })

      .catch((err) => {
        if (err.response.status === 400) {
          setErr(err.response.data.error.message);
        }
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3} sx={{ my: 2 }}>
        <TextField
          name="email"
          id="email"
          label="Email address"
          value={formik.values.email}
          onChange={formik.handleChange}
          // onChange={(e)=>setEmail(e.target.value)}
        />
        <div style={{ color: 'red', marginTop: '5px', fontSize: '14px' }}>{formik.errors.email}</div>
        <TextField
          name="password"
          id="password"
          label="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          // value={password}
          // onChange={(e)=>setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div style={{ color: 'red', marginTop: '5px', fontSize: '14px' }}>{formik.errors.password}</div>
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}
      {<div style={{ color: 'red', marginBottom: '10px', fontSize: '14px' }}>{err}</div>}
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </form>
  );
}
