import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import PhotographerPage from './pages/PhotographerPage';
import GirlPage from './pages/GirlsPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import AffiliatePage from './pages/AffiliatePage';
import WithdrawlList from './pages/WithdrawlList';
import DashboardAppPage from './pages/DashboardAppPage';
import { PrivateRoute } from './components/PrivateRoute';
import PartnerList from './pages/PartnerList';

// ----------------------------------------------------------------------

export default function Router() {
  // const PrivateRoute = ({ auth: { isAuthenticated }, children }) => {
  //   return isAuthenticated ? children : <Navigate to="/login" />;
  // };

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <PrivateRoute>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'girls', element: <GirlPage /> },
        { path: 'affiliates', element: <AffiliatePage /> },
        { path: 'photographers', element: <PhotographerPage /> },
        { path: 'withdrawalList', element: <WithdrawlList /> },
        { path: 'partner', element: <PartnerList /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
